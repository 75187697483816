import { useEffect, useState } from 'react'
import styles from './StartSimulationForm.module.scss'
import classNames from 'classnames'
import { CustomerFlows, InputGroupKeys, PersonalDataFieldKeys, SimulationFieldKeys } from 'store/customer/enums'
import { AddressFields } from 'components/form-fields/address-section/types'
import { GasAndElectricity } from 'assets/svg'
import { Electricity } from 'components/producers/icons/Icons'
import { Button } from '@boltenergy-be/design-system'
import { useForm } from 'react-hook-form'
import { SimulationSteps } from 'features/simulation/types'
import { isValidPostalCode } from 'utils/helpers'
import { getTown } from 'api/customerFlow'
import { determineSimulationUsage, requestAndSavePropositions } from 'features/simulation/utils'
import { CompanyType, FuelType, StartSimulationFormInputs, StartSimulationFormProps } from 'features/home/start-simulation-form/types'
import { useTranslation } from 'next-i18next'
import { checkIsEavOrFixed, startSimulationOrRegistration } from 'utils/customerFlow'
import { goToStep, updateAllInputs, updateInput, updateSimulatedPropositions } from 'store/customer/slice'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'
import { getUsableProducts } from 'utils/products'
import { CtaTrackingVariant } from 'types/simulation'
import { Heading } from '@boltenergy-be/design-system'
import { PostalCode } from 'constants/regex'
import { Town } from 'types/towns'
import { Region } from 'types/region'
import { log } from 'utils/logging'

const StartSimulationForm = ({ className, specificProduct }: StartSimulationFormProps) => {
  // Redux store
  const { inputs, propositions } = useStoreSelector((store) => store.customer)
  const { personalData, simulation } = inputs
  const { didSimulation, currentStep } = useStoreSelector((store) => store.customer.flows.simulation)
  const dispatch = useStoreDispatch()

  // i18n
  const { t } = useTranslation(['common', 'simulation', 'commonFormFields'])

  // React Hook Form
  const { handleSubmit, watch, register, setValue, formState } = useForm<StartSimulationFormInputs>({
    mode: 'onBlur',
    defaultValues: {
      companyType: personalData[PersonalDataFieldKeys.IS_COMPANY] ? CompanyType.COMPANY : CompanyType.RESIDENTIAL,
      fuelType: personalData[PersonalDataFieldKeys.NEEDS_GAS] ? FuelType.ELECTRICITY_GAS : FuelType.ELECTRICITY,
      postalCode: personalData[PersonalDataFieldKeys.DELIVERY_ADDRESS]?.[AddressFields.POSTAL_CODE] || ''
    }
  })
  const watchFuelType = watch('fuelType')

  // Local state
  const [postalCodeError, setPostalCodeError] = useState<'api' | 'invalid' | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  // Constants
  const isEavOrFixed = checkIsEavOrFixed(inputs.simulation.chosenSimulationType)

  /**
   * Triggered everytime the personalData inputs change (Needed because the page loads before the values are in the store)
   * Sets the values in the form if they change in the store
   */
  useEffect(() => {
    setValue('companyType', personalData[PersonalDataFieldKeys.IS_COMPANY] ? CompanyType.COMPANY : CompanyType.RESIDENTIAL)
    setValue('fuelType', personalData[PersonalDataFieldKeys.NEEDS_GAS] ? FuelType.ELECTRICITY_GAS : FuelType.ELECTRICITY)
    setValue('postalCode', personalData[PersonalDataFieldKeys.DELIVERY_ADDRESS]?.[AddressFields.POSTAL_CODE])
  }, [personalData, setValue])

  /**
   * Fetch towns & update delivery address in store
   * @param {number|null} postalCode
   */
  const fetchTown = async (postalCode: number | null): Promise<{ town: Town; region: Region }> => {
    try {
      // Fetch the towns & the region
      const { towns: fetchedTowns, region } = await getTown(postalCode)

      return { town: fetchedTowns[0], region }
    } catch (e) {
      setPostalCodeError('api')
    }
  }

  /**
   * Handles form submission after validation by React Hook Form
   */
  const onSubmit = async (data: StartSimulationFormInputs) => {
    setLoading(true)

    try {
      let fetchTownResponse: { town: Town; region: Region } | null

      // Redirect to first step of simulation if no postal code was filled in
      if (!data.postalCode) {
        dispatch(goToStep({ flow: CustomerFlows.SIMULATION, step: SimulationSteps.POSTAL_CODE }))
      } else {
        // Fetch town & region
        fetchTownResponse = await fetchTown(data.postalCode ? Number(data.postalCode) : null)
      }

      // Check if the input has changed
      const hasChangedInput =
        personalData[PersonalDataFieldKeys.DELIVERY_ADDRESS][AddressFields.POSTAL_CODE] !== data.postalCode ||
        personalData[PersonalDataFieldKeys.IS_COMPANY] !== (data.companyType === CompanyType.COMPANY) ||
        personalData[PersonalDataFieldKeys.NEEDS_GAS] !== (data.fuelType === FuelType.ELECTRICITY_GAS)

      // Update the store
      dispatch(
        updateAllInputs({
          inputs: {
            ...inputs,
            [InputGroupKeys.PERSONAL_DATA]: {
              ...personalData,
              [PersonalDataFieldKeys.IS_COMPANY]: data.companyType === CompanyType.COMPANY,
              [PersonalDataFieldKeys.NEEDS_GAS]: data.fuelType === FuelType.ELECTRICITY_GAS,
              [PersonalDataFieldKeys.DELIVERY_ADDRESS]: fetchTownResponse
                ? {
                    ...personalData[PersonalDataFieldKeys.DELIVERY_ADDRESS],
                    postalCode: fetchTownResponse.town.postalCode.toString(),
                    townCode: fetchTownResponse.town.townCode,
                    townName: fetchTownResponse.town.townName
                  }
                : personalData[PersonalDataFieldKeys.DELIVERY_ADDRESS]
            },
            [InputGroupKeys.SIMULATION]: {
              ...simulation,
              [SimulationFieldKeys.REGION]: fetchTownResponse?.region ?? simulation[SimulationFieldKeys.REGION]
            }
          }
        })
      )

      // If they changed the input & already did a simulation OR there was a specific product selected,
      // we need to reset the simulated propositions & simulate again for the new usable products
      if ((didSimulation && hasChangedInput) || propositions.simulateSpecificProduct) {
        // Reset the simulated propositions
        dispatch(updateSimulatedPropositions({ propositions: null }))
        // Get the new usable products
        const usableProducts = getUsableProducts({ region: data.region })

        // If they changed the input of the form, we need to update the usage
        if (hasChangedInput) {
          dispatch(updateInput({ group: InputGroupKeys.SIMULATION, key: SimulationFieldKeys.USAGE, value: determineSimulationUsage() }))
        }

        await requestAndSavePropositions({ products: usableProducts })
      }

      const startFromUsage = data.postalCode && [SimulationSteps.POSTAL_CODE, SimulationSteps.ENERGY_TYPE].includes(currentStep)

      return startSimulationOrRegistration({
        ctaTrackingVariant: CtaTrackingVariant.START_SIMULATION_FORM,
        specificProduct,
        startFromUsage
      })
    } catch (e) {
      log({ error: 'Failed to submit StartSimulationForm', identifier: '[simulation:StartSimulationForm]' })
      return
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className={classNames(styles.cta, className)}>
      <Heading as="h3" variant="h6" className="mb-400">
        {t('startSimulationForm.title', 'Kies voor duurzame stroom van een lokale energie-opwekker')}
      </Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          type="number"
          inputMode="numeric"
          aria-label="postalCode"
          placeholder={t('commonFormFields:zipcode', 'Postcode')}
          className={classNames('border', { 'has-error': !!postalCodeError || formState.errors.postalCode })}
          {...register('postalCode', {
            min: 0,
            max: 9999,
            pattern: PostalCode,
            validate: (value) => (value ? isValidPostalCode(Number(value)) : true)
          })}
        />

        <select name="companyType" aria-label="companyType" {...register('companyType')}>
          <option value={CompanyType.RESIDENTIAL}>{t('startSimulationForm.residence', 'Particulier')}</option>
          <option value={CompanyType.COMPANY}>{t('startSimulationForm.company', 'Professioneel')}</option>
        </select>

        <div className={styles['prepended-select']}>
          <span className={styles.icon}>{watchFuelType === FuelType.ELECTRICITY_GAS ? <GasAndElectricity /> : <Electricity />}</span>
          <select name="fuelType" aria-label="fuelType" {...register('fuelType')}>
            <option value={FuelType.ELECTRICITY}>{t('startSimulationForm.electricity', 'Elektriciteit')}</option>
            <option value={FuelType.ELECTRICITY_GAS}>{t('startSimulationForm.electricityAndGas', 'Elektriciteit & gas')}</option>
          </select>
        </div>

        <Button loading={loading} className={styles['simulate-button']} type="submit">
          {didSimulation || isEavOrFixed ? t('switchNow', 'Stap nu over') : t('calculateProposal', 'Bereken jouw voorstel')}
        </Button>
      </form>
    </div>
  )
}

export default StartSimulationForm
